<template>
  <!-- eslint-disable -->
  <div class="theme-switcher">
    <ThemeSwitcher />
    <div v-if="username" class="current-user">
      <span>{{ username }}</span>
      <img v-if="!store.state.connectedTo" width="20" height="20" src="https://img.icons8.com/fluency/48/delete-sign.png" @click="clearUsernameClicked" />
    </div>
  </div>
  <div v-if="store.state.connectedTo" class="connected-to-section" @click="showDisconnectConfirm = true">
    <div>
      <img width="20" height="20" src="https://img.icons8.com/ios-filled/50/connected.png" />
      <div style="display: flex; align-items: center">
        {{ store.state.connectedTo }}
        <span style="font-size: 0.75em; margin-left: 5px">{{ isUserConnected ? '🟢' : '🔴' }}</span>
      </div>
    </div>
    <div>{{ store.state.connectionCountdown }}</div>
  </div>
	<transition name="appear">
		<div v-if="!username" class="name-input-container">
      <div class="name-input-box">
        <div>Please enter your name</div>
        <Input v-model:value="userInput" :minLength="5" showLiveError placeholder="Username" />
        <button @click="saveNameClicked">Save Name</button>
      </div>
		</div>
	</transition>

  <Popup :show="showDisconnectConfirm">
    <div>Are you sure you want to disconnect from {{ store.state.connectedTo }}?</div>
    <button class="danger-button" @click="confirmCancelDC(true)">Disconnect</button>
    <button @click="confirmCancelDC(false)">Cancel</button>
  </Popup>

  <Popup :show="showDisconnectedPopup">
    <div>{{ store.state.connectedTo }} has disconnect the connection</div>
    <button @click="acknowledgeDC">OK</button>
  </Popup>

  <Popup :show="showConnectionEndedPopup">
    <div>Your connection has ended</div>
    <button @click="showConnectionEndedPopup = false">OK</button>
  </Popup>

  <router-view></router-view>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import ThemeSwitcher from './components/ThemeSwitcher.vue';
import Input from './components/Input.vue';
import CodeGenerator from './components/CodeGenerator.vue';
import Popup from './components/Popup.vue';
import { clearStorage } from './js/common';

//#region Data
const username = ref('user');
const userInput = ref('');
const showDisconnectConfirm = ref(false);
const showDisconnectedPopup = ref(false);
const showConnectionEndedPopup = ref(false);
const isUserConnected = ref(false);

const store = useStore();
const router = useRouter();
//#endregion Data

//#region Methods
const saveNameClicked = () => {
  if (userInput.value) {
    localStorage.setItem('username', userInput.value);
    username.value = userInput.value;
    store.state.username = username.value;
  }
}
const clearUsernameClicked = () => {
  username.value = '';
  localStorage.removeItem('username');
}
const confirmCancelDC = (dc) => {
  showDisconnectConfirm.value = false;
  if (dc) { // Confirm DC
    // Invoke the disconnect method
    store.state.hub.invoke('Disconnect', localStorage.getItem('connectionId'));

    // Remove all connection details from localstorage
    clearStorage();

    // Clear connected to in vuex
    store.state.connectedTo = '';

    // Push to connection page
    router.push('/');
  }
}
const acknowledgeDC = () => {
  showDisconnectedPopup.value = false;

  // Remove all connection details from localstorage
  clearStorage();
  
  // Clear connected to in vuex
  store.state.connectedTo = '';

  // Push to connection page
  router.push('/');
}
//#endregion Methods

//#region Hub Methods
const userDisconnect = (connId) => {
  // Check if the connection id is same as current
  if (localStorage.getItem('connectionId') == connId) {
    // Remove all connection details from localstorage
    clearStorage();

    // Show popup (push to connection page after click ok in the popup)
    showDisconnectedPopup.value = true;
  }
}
const onlineState = (name, state) => {
  // Only update if the connected to is same with the name passed
  if (store.state.connectedTo == name) {
    isUserConnected.value = state;
  }
}
//#endregion Hub Methods

onMounted(() => {
  store.commit('startHub');
  username.value = localStorage.getItem('username') || '';
  // Setting the global username in store
  store.state.username = username.value;

  // When the other person received the disconnection from the otherr party
  store.state.hub.on('DisconnectReceived', userDisconnect);

  // When receiving online/offline status from the other user
  store.state.hub.on('UpdateOnlineStateReceived', onlineState)

  store.subscribe((mutation, state) => {
    if (mutation.type == 'connectionEnded') {
      showConnectionEndedPopup.value = true;
    }
  });

  // Watching when the visibility changed of the page (includes change tab, minimize, [PENDING]close browser in phone)
  document.addEventListener('visibilitychange', () => {
    if (store.state.connectedTo) { // Only run when there is a user connected to
      store.state.hub.invoke('UpdateOnlineState', store.state.username, !document.hidden);
    }
  })
});
</script>

<style scoped>
.name-input-container {
	height: 100vh;
	max-height: -webkit-fill-available;
	width: 100vw;
	background-color: rgba(128,128,128,0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
}
.name-input-box {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  box-shadow: 2px 2px 5px 1px var(--font-color);
  background-color: var(--bg-color);
}
.name-input-box > :not(:first-child) {
  margin-top: 10px;
}
.theme-switcher {
  position: fixed !important;
  top: 10px;
  right: 10px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.current-user {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.current-user > img {
  margin-left: 5px;
  cursor: pointer;
}
.connected-to-section {
  position: fixed;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.connected-to-section > :first-child {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.connected-to-section > :first-child > img {
  filter: invert(var(--img-invert));
  margin-right: 5px;
}

.appear-enter-active, .appear-leave-active {
  transition: 0.3s;
}
.appear-enter-from, .appear-leave-to {
  opacity: 0;
  transform: scale(0.75);
  -ms-transform: scale(0.75);
  -webkit-transform: scale(0.75);
}
</style>

<style>
* {
  box-sizing: border-box;
}
body {
	margin: 0;
  background-color: var(--bg-color);
  color: var(--font-color);
}
#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}
button {
  color: var(--font-color);
  background-color: var(--secondary-color);
  border: 1px solid var(--font-color);
  box-shadow: 0 0 5px 1px var(--font-color);
  padding: 8px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}
.danger-button {
  background-color: var(--danger-color);
}
button:active {
  transform: scale(0.95);
  box-shadow: none;
}
button:disabled {
  box-shadow: none;
  opacity: 0.3;
}
:root {
  --primary-color: #302AE6;
  --secondary-color: #aac1ff;
  --font-color: #424242;
  --danger-color: #d27b7b;
  --bg-color: #fff;
  --heading-color: #292922;
  --img-invert: 0;
}
[data-theme="dark"] {
  --primary-color: #9A97F3;
  --secondary-color: #728edb;
  --font-color: #f8f8ff;
  --danger-color: #ff2c2c;
  --bg-color: #161625;
  --heading-color: #818cab;
  --img-invert: 1;
}
</style>