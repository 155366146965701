import store from '../store/index';
import axios from 'axios';

export const clearStorage = () => {
  axios.delete(`/Message/ClearMessage?conId=${localStorage.getItem('connectionId')}`);

  localStorage.removeItem('connectedTo');
  localStorage.removeItem('connectionId');
  localStorage.removeItem('connectionExpired');

  store.state.connectedTo = '';
}
export const padNum = (num) => {
  if (num > 9) {
    return `${num}`;
  } else {
    return `0${num}`;
  }
}