<template>
	<div class="theme-main" @click="themeClicked">
		<div class="theme-switch" :style="{ left: darkThemed ? 'calc(100% - 25px)' : '5px' }">
			<transition name="fade">
				<img v-if="darkThemed" src="https://img.icons8.com/ios-glyphs/30/moon-symbol.png" />
				<img v-else src="https://img.icons8.com/ios-filled/50/sun--v1.png" />
			</transition>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';

//#region Data
const darkThemed = ref(false);
//#endregion Data

//#region Methods
const themeClicked = () => {
	darkThemed.value = !darkThemed.value;
  localStorage.setItem('darktheme', darkThemed.value);
}
//#endregion

//#region Watcher
watch(darkThemed, (val) => {
  document.documentElement.setAttribute('data-theme', val ? 'dark' : 'light');
});
//#endregion Watcher

onMounted(() => {
  let currentTheme = localStorage.getItem('darktheme');
  if (currentTheme) {
    darkThemed.value = currentTheme == 'true';
  } else {
    darkThemed.value = false;
    localStorage.setItem('darktheme', false);
  }
});
</script>

<style scoped>
.theme-main {
	background-color: var(--bg-color);
	border-radius: 30px;
	width: 50px;
	height: 30px;
	box-shadow: inset 0 0 5px 1px var(--font-color);
	padding: 5px;
  position: relative;
	cursor: pointer;
  user-select: none;
}
.theme-switch {
	height: 20px;
	width: 20px;
	border-radius: 100%;
  transition: 0.3s;
  position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
}
.theme-switch > img {
	height: 80%;
	position: absolute;
  filter: invert(var(--img-invert));
}

.fade-enter-active, .fade-leave-active {
	transition: 0.3s;
}
.fade-enter-from, .fade-leave-to {
	transform: scaleX(0);
	-ms-transform: scaleX(0);
	-webkit-transform: scaleX(0);
	opacity: 0;
}
</style>