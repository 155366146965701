import { createStore } from 'vuex';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { clearStorage, padNum } from '../js/common';
import router from '../router/index';

const calculateCountdown = () => {
  let expiredTime = localStorage.getItem('connectionExpired') || '';
  if (expiredTime) {
    let expired = new Date(expiredTime);
    let diff = expired.getTime() - new Date().getTime();
  
    let secs = Math.floor(diff / 1000);
    let mins = Math.floor(secs / 60);
    let hrs = Math.floor(mins / 60);
  
    if (mins > 59) {
      mins = mins % 60;
    }
  
    if (secs > 59) {
      secs = secs % 60;
    }
    
    return {
      display: `${padNum(hrs)}:${padNum(mins)}:${padNum(secs)}`,
      total: diff
    };
  } else {
    return {
      display: `00:00:00`,
      total: -999
    };
  }
}

export default createStore({
  state: {
    username: '',
    hub: null,
    defaultConnectTime: 10, // in seconds
    defaultConnectionExpired: 120, // In minutes, how long can a user connected to another user
    connectedTo: '', // The username of whom the user connected to
    connectionCountdown: '',

    countdownTimer: null,
  },
  mutations: {
    startHub(state) {
      state.hub = new HubConnectionBuilder().withUrl('/messenger').build();
      state.hub.start();
    },
    startCountdown(state) {
      state.connectionCountdown = calculateCountdown().display;
      state.countdownTimer = setInterval(() => {
        let time = calculateCountdown();
        state.connectionCountdown = time.display;
        if (time.total < 1000) {
          if (state.countdownTimer) {
            clearInterval(state.countdownTimer);
            clearStorage();
            router.push('/');
            if (time.total != -999) {
              this.commit('connectionEnded');
            }
          }
        }
      }, 1000);
    },
    stopCountdown(state) {
      if (state.countdownTimer) {
        clearInterval(state.countdownTimer);
      }
    },
    connectionEnded() {}
  },
  actions: {
  },
  modules: {
  }
})
