<template>
  <transition name="popup">
    <div v-if="props.show" class="popup-main">
      <div class="popup-content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { defineProps } from 'vue';

//#region Props
const props = defineProps({
  show: { type: Boolean, default: false }
})
//#endregion Props
</script>

<style scoped>
.popup-main {
	height: 100vh;
	max-height: -webkit-fill-available;
	width: 100vw;
	background-color: rgba(128,128,128,0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
}
.popup-content {
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  box-shadow: 2px 2px 5px 1px var(--font-color);
  background-color: var(--bg-color);
}
.popup-content > :not(:first-child) {
  margin-top: 10px;
}
.popup-enter-active, .popup-leave-active {
  transition: 0.3s;
}
.popup-enter-from, .popup-leave-to {
  opacity: 0;
  transform: scale(0.5);
	-ms-transform: scale(0.5);
	-webkit-transform: scale(0.5);
}
</style>