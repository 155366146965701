<template>
  <!-- eslint-disable -->
	<div class="code-main">
		<div v-for="(l,i) in valueLength" :key="i" class="code-box" :style="boxStyle">
      <div v-for="(c,ci) in allChar" :key="ci" class="code-char"
        :style="{ opacity: code[i] == c ? '1' : '0' }">{{ c }}</div>
    </div>
	</div>

  <div class="code-message" v-if="codeGenerated">
    <div>Provide this code for other people to connect.</div>
    <div>Make sure you provided to trusted person only.</div>
  </div>
  <div class="code-message" v-else>Generating code . . .</div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, defineEmits, onMounted, ref, watch } from 'vue';

//#region Data
const boxStyle = ref({});
const allChar = ref(['1','2','3','4','5','6','7','8','9','0','a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']);
const code = ref([]);
const time = ref([500,300,200,100,50,100,200,300,500]);
const codeCount = ref(0);
const codeGenerated = ref(false);
//#endregion Data

//#region Props
const props = defineProps({
	valueLength: { type: Number, default: 4 },
  code: { type: String, default: '' }
});
const emit = defineEmits([
  'update:code'
]);
//#endregion Props

//#region Methods
const codeInput = (e) => {
	let data = e.target.value;
	let hasMatch = data.match(/\d/g);
	if (!hasMatch) {
		e.target.value = '';
	}
}
const calculateSize = () => {
  let winWidth = window.innerWidth;
  let margins = props.valueLength * 20 + 50; // Because each box has margin of 10 left and right

  let boxSize = (winWidth - margins) / 4;
  if (boxSize > 100) {
    boxSize = 100;
  }
  boxStyle.value.width = `${boxSize}px`;
  boxStyle.value.height = `${boxSize}px`;
  boxStyle.value.fontSize = `${boxSize / 2}px`;
}
const sleep = (ms) => {
  return new Promise(r => setTimeout(() => r(), ms));
}
const codeAnim = async (codeIndex) => {
  for (let t of time.value) {
    if (t == 50) {
      let loop50 = Math.floor(Math.random() * 15) + 35;
      for (let x = 0; x < loop50; x++) {
        let index = Math.floor(Math.random() * allChar.value.length);
        code.value[codeIndex] = allChar.value[index];
        await sleep(t);
      }
    } else {
      let index = Math.floor(Math.random() * allChar.value.length);
      code.value[codeIndex] = allChar.value[index];
      await sleep(t);
    }
  }

  codeCount.value++;
}
//#endregion Methods

//#region Watcher
watch(codeCount, (val) => {
  codeGenerated.value = val == props.valueLength;
  emit('update:code', code.value.join(''));
})
//#endregion Watcher

onMounted(() => {
  calculateSize();
  window.addEventListener('resize', calculateSize);

  for (let c = 0; c < props.valueLength; c++) {
    codeAnim(c);
  }
});
</script>

<style scoped>
.code-main {
	display: flex;
	align-items: center;
	justify-content: center;
  margin-top: 10em;
}
.code-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  box-shadow: 0 0 5px 2px var(--font-color);
  margin: 10px;
  position: relative;
}
.code-char {
  position: absolute;
  font-family: "Red Hat Mono", monospace;
}
.code-message {
  width: 100%;
  text-align: center;
  font-size: 1.25em;
  margin-top: 10px;
}
</style>