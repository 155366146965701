<template>
  <!-- eslint-disable -->
  <input type="text" :class="{ 'input-error': showLiveError && error }" @input="inputChanged" :placeholder="placeholder"
    :style="{ textAlign: textAlign }" :disabled="disabled" @keypress="inputKeyPress" ref="myInput" />
	<div class="error-message" v-if="showLiveError && error">{{ error }}</div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';

//#region Data
const props = defineProps({
	value: { type: String, default: '' },
	maxLength: { type: Number, default: 0 },
	minLength: { type: Number, default: 0 },
	showLiveError: { type: Boolean, default: false },
  placeholder: { type: String, default: 'Text' },
  textAlign: { type: String, default: 'left' },
	disabled: { type: Boolean, default: false }
});
const emit = defineEmits([
	'update:value',
	'enter'
]);

const error = ref('');
const myInput = ref(null);
//#endregion Data

//#region Methods
const inputChanged = (e) => {
	let inputData = e.target.value;

	if (!inputData) {
		error.value = '';
		emit('update:value', inputData);
		return;
	}

	if (props.minLength > 0 && props.maxLength > 0 && props.maxLength > props.minLength) { // Text must be in between max and min length
		if (inputData.length < props.minLength || inputData.length > props.maxLength) {
			inputData = null;
			error.value = `${props.placeholder} length must be between ${props.minLength} and ${props.maxLength}`;
		} else {
			error.value = '';
		}
	} else if (props.minLength > 0 && props.maxLength == 0) { // Text must be more than the min length
		if (inputData.length < props.minLength) {
			inputData = null;
			error.value = `${props.placeholder} length must be more than ${props.minLength}`;
		} else {
			error.value = '';
		}
	} else if (props.minLength == 0 && props.maxLength > 0) { // Text must be less than the max length
		if (inputData.length > props.maxLength) {
			inputData = null;
			error.value = `${props.placeholder} length must be less than ${props.maxLength}`;
		} else {
			error.value = '';
		}
	}
	
	emit('update:value', inputData);
}
const inputKeyPress = (e) => {
	if (e.key == 'Enter') {
		emit('enter');
	}
}
//#endregion Methods

//#region Watcher
watch(() => props.value, (val) => {
	myInput.value.value = val;
})
//#endregion Watcher
</script>

<style scoped>
input {
	padding: 10px;
	width: 100%;
	border-radius: 5px;
	border: 1px solid var(--font-color);
  color: var(--font-color);
  background-color: var(--bg-color);
}
.input-error {
	border: 1px solid indianred;
}
input:focus {
	outline: none;
}
input:disabled {
	opacity: 0.3;
}
.error-message {
	color: indianred;
}
</style>